// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-pages-about-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-am-about-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/about.js" /* webpackChunkName: "component---src-pages-am-about-js" */),
  "component---src-pages-am-book-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/book.js" /* webpackChunkName: "component---src-pages-am-book-js" */),
  "component---src-pages-am-index-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/index.js" /* webpackChunkName: "component---src-pages-am-index-js" */),
  "component---src-pages-am-privacy-policy-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/privacy-policy.js" /* webpackChunkName: "component---src-pages-am-privacy-policy-js" */),
  "component---src-pages-am-send-message-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/send-message.js" /* webpackChunkName: "component---src-pages-am-send-message-js" */),
  "component---src-pages-am-social-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/social.js" /* webpackChunkName: "component---src-pages-am-social-js" */),
  "component---src-pages-am-terms-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/terms.js" /* webpackChunkName: "component---src-pages-am-terms-js" */),
  "component---src-pages-am-what-is-medemer-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/am/what-is-medemer.js" /* webpackChunkName: "component---src-pages-am-what-is-medemer-js" */),
  "component---src-pages-book-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-index-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-send-message-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/send-message.js" /* webpackChunkName: "component---src-pages-send-message-js" */),
  "component---src-pages-social-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-terms-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-is-medemer-js": () => import("/Users/mike/projects/ethiopia/medemer/medemer/src/pages/what-is-medemer.js" /* webpackChunkName: "component---src-pages-what-is-medemer-js" */)
}

