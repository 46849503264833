module.exports = [{
      plugin: require('/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-147970486-1"},
    },{
      plugin: require('/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mike/projects/ethiopia/medemer/medemer/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
